// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tags-js": () => import("../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tag-js": () => import("../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-build-post-js": () => import("../src/templates/buildPost.js" /* webpackChunkName: "component---src-templates-build-post-js" */),
  "component---src-templates-keyboard-post-js": () => import("../src/templates/keyboardPost.js" /* webpackChunkName: "component---src-templates-keyboard-post-js" */),
  "component---src-templates-keycap-set-post-js": () => import("../src/templates/keycapSetPost.js" /* webpackChunkName: "component---src-templates-keycap-set-post-js" */),
  "component---src-templates-keycap-sets-js": () => import("../src/templates/keycapSets.js" /* webpackChunkName: "component---src-templates-keycap-sets-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-builds-js": () => import("../src/pages/builds.js" /* webpackChunkName: "component---src-pages-builds-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyboards-js": () => import("../src/pages/keyboards.js" /* webpackChunkName: "component---src-pages-keyboards-js" */)
}

